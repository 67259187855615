<template>
    <div>
        <!-- <van-cell-group title="筛选">
            <van-dropdown-menu>
                <van-dropdown-item v-model="sea.RESULT" @change="filter" :options="[
                    { text: '全部结果', value: 0 },
                    { text: '正常', value: 9 },
                    { text: '异常', value: 1 }
                ]" />
            </van-dropdown-menu>
            <van-search v-model="sea.KEY" placeholder="请输入搜索关键字" @search="filter" @clear="filter" />
            <van-cell title="日期" :value="date.val" @click="date.show = true" is-link />
            <van-calendar v-model="date.show" type="range" @confirm="dateSel" :allow-same-day="true"
                :min-date="date.minDate" />
        </van-cell-group> -->
        <!-- <van-divider /> -->
        <div class="top">
            <!-- <div class="tit">按{{ date }}</div> -->
            <div class="screen" @click="changeScreen">筛选<img src="../../../assets/home/xtb_sx.png" alt=""></div>
            <!-- <div class="date" @click="changeDate">时间范围<img src="../../../assets/home/xtb_sx.png" alt=""></div> -->
        </div>
        <van-empty description="暂无数据" v-if="lst.da.length == 0" />
        <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading" style="padding: 10px 0px;">
            <van-cell v-for="item in lst.da" :key="item.ID" :value="item.RET_TXT"
                :icon="item.RESULT == 9 ? 'success' : 'cross'" @click="goPage(item)">
                <div slot="title">
                    {{ item.CONF_NAME }}
                    <span style="color: #666; margin-left: 10px">[{{ item.SUB_NAME }}]</span>
                </div>
                <div slot="label">
                    <span style="margin-right: 10px">[{{ item.CREATED_DT | datetime('YYYY-MM-DD HH:mm') }}]</span>
                    <span>{{ item.DESCRIPTION }}</span>
                    <van-icon name="photo-o" size="20" @click="prvImage(item.PIC_URL)" v-if="item.PIC_NAME" />
                    <p></p>
                </div>
            </van-cell>
        </van-list>
        <van-popup v-model="scr.show" position="bottom">
            <van-picker show-toolbar :columns="screen" @cancel="screenCancel" @confirm="screenConfirm" />
        </van-popup>
    </div>
</template>

<style lang="less" scoped>
.top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #f6f7f8;
    background: #fff;
    color: #222222;
    font-size: 14px;
    padding: 10px 10px;

    .screen {
        img {
            width: 12px;
            height: 12px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 4px;
        }
    }
}

.wcard {
    margin: 0 12px 12px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, .1)
}

.wcard .ft {
    border-top: 1px dashed #ebedf0;
    padding: 8px 16px;
    font-size: 12px;
    min-height: 20px
}

.wcard .bd {}

.wcard .bd .lf {
    text-align: center;
    color: #ee0a24;
    padding-top: 10px
}

.wcard .bd .lf h2 {
    font-size: 30px;
    font-weight: 500;
    display: inline;
}

.wcard .bd .lf span {
    font-size: 40%;
    font-weight: normal;
    margin-left: 2px
}

.wcard .bd .lf p {
    font-size: 14px;
    line-height: 16px
}

.center {
    .info {
        width: 90%;
        margin: 10px auto;

        .count {
            background: #58aaf2;
            color: #fff;
            padding: 5px 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 14px;

            span {
                margin: 0 5px;
            }
        }

        .detail {
            background: #fff;
            padding: 10px 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .pro {
                margin: 10px 0;
                display: flex;
                align-items: center;
                font-size: 15px;
                justify-content: space-between;

                span {
                    display: inline-block;
                    width: 40%;
                }
            }
        }
    }
}
</style>

<script>
import moment from "moment";
import { ImagePreview } from "vant";
export default {
    name: "index",
    data() {
        return {
            sea: {
                PAGE_SIZE: 10,
                PAGE_INDEX: -1,
                KEY: "",
                RESULT: 0
            },
            lst: {
                da: [],
                loading: false,
                finish: false
            },

            date: {
                show: false,
                val: "",
                minDate: moment('2021-07-01').toDate()
            },
            token: localStorage.getItem('loginToken'),

            scr: { show: false },
            screen: ['全部', '正常', '异常']
        }
    },
    props: {
        name: String,
        id: String,
        schId: String
    },
    created() {
        if (this.$route.query.dts)
            this.sea.DTS = moment(this.$route.query.dts).format("YYYY-MM-DD");
        if (this.$route.query.dte)
            this.sea.DTE = moment(this.$route.query.dte).format("YYYY-MM-DD");
        
        this.sea.Safe_Class_Name = this.name
        this.sea.Safe_Class_Id = Number(this.id)
        // if (this.$route.query.sch_id)
        //     this.sea.SCH_ID = this.$route.query.sch_id;
        if (this.$route.query.SCH_ID){
            this.sea.SCH_ID = this.$route.query.SCH_ID;
            // this.sea.Safe_Class_Name = this.name
            // this.sea.Safe_Class_Id = Number(this.id)
        }
        // this.filter()
    },
    methods: {
        filter() {
            this.lst.da.length = 0;
            this.lst.finish = false;
            this.sea.PAGE_INDEX = 0;
            this.sea.Safe_Class_Name = this.name
            this.sea.Safe_Class_Id = Number(this.id)
            this.sea.SCH_ID = this.schId
            if (this.$route.query.SCH_ID) {
                this.getList1();
            } else {
                this.getList()
            }
        },
        changeScreen() {
            this.scr.show = true
        },
        screenCancel() {
            this.scr.show = false
        },

        screenConfirm(e) {
            console.log(e)
            if (e == '全部') {
                this.sea.RESULT = ''
            } else if (e == '正常') {
                this.sea.RESULT = '9'
            } else {
                this.sea.RESULT = '1'
            }
            this.scr.show = false
            this.filter()
        },
        loadPage() {
            this.sea.PAGE_INDEX += 1;            
            if (this.$route.query.SCH_ID) {
            
                this.getList1();
            } else {
                this.getList()
            }
        },
        getList() {
            let self = this;
            this.whale.remote.getCollection({
                url: "/api/Mobile/SAFE/RecApi/GetList",
                data: this.sea,
                completed(its, n) {
                    console.log('its',its)
                    self.lst.da.push(...its.ITEMS);
                    self.lst.loading = false;

                    console.log('length',self.lst.da.length)
                    console.log('n',n)
                    if (self.lst.da.length >= n) self.lst.finish = true;
                }
            })
        },
        getList1() {
            let self = this;
            this.whale.remote.getCollection({
                url: "/api/Mobile/SAFE/RecApi/GetListOutsiders",
                data: this.sea,
                completed(its, n) {
                    self.lst.da.push(...its.ITEMS);
                    self.lst.loading = false;
                    if (self.lst.da.length >= n) self.lst.finish = true;
                }
            })
        },
        dateSel(d) {
            this.sea.DTS = moment(d[0]).format('YYYY-MM-DD');
            this.sea.DTE = moment(d[1]).format('YYYY-MM-DD')
            this.date.show = false;
            this.date.val = this.sea.DTS + ' 至 ' + this.sea.DTE;
            this.filter();
        },
        prvImage(url1) {
            let us = [];
            if (url1 != undefined) us.push(url1)
            ImagePreview(us)
        },
        goPage(item) {
            this.$router.push('/safe/info?id=' + item.ID)
        }
    }
}
</script>