<template>
    <div>
        <van-tabs :active="active" sticky color="#1492ff">
            <van-tab title="巡检记录" name="1">
                <Rec :name="name" :id="id" :schId="schId"></Rec>
            </van-tab>
            <van-tab title="巡检统计" name="2">
                <Count :name="name" :id="id" :date="date" :schId="schId"></Count>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import Rec from './new.vue'
import Count from './count.vue'
export default {
    components: { Rec, Count },
    data() {
        return {
            active: 1,
            name: this.$route.query.name,
            id: this.$route.query.Safe_Class_Id,
            date: this.$route.query.date,
            schId: this.$route.query.SCH_ID || ''
        }
    },
    created() {
        // console.log(this.$route.query)
        document.title = this.$route.query.name
    },
    methods: {

    },
}
</script>

<style lang="less" scoped></style>